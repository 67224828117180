.about-section {
    font-size: 14px;
    line-height: 22px;

    @media (min-width: $tablet-viewport) {
        font-size: 18px;
        line-height: 24px;
    }

    h1 {
        font-size: 56px;
        line-height: 56px;

        @media (min-width: $tablet-viewport) and (max-width: $desktop-viewport) {
            margin-bottom: 40px;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 32px;
    }

    h1, h2 {
        @media (max-width: $tablet-viewport) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    &__container {
        padding-bottom: 104px;

        @media (max-width: $tablet-viewport) {
            padding-top: 40px;
            padding-bottom: 32px;
        }
    }

    &__row {
        @media (min-width: $desktop-viewport) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
    }

    &__right {
        @media (min-width: $tablet-viewport) and (max-width: $desktop-viewport) {
            padding-left: 10em;
        }
    }
}