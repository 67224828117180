//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: "HostGrotesk", sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$primary:    #102630;
$secondary:  #6c757d;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      #f8f9fa;
$dark:       #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

$desktop-viewport: 1025px;
$tablet-viewport: 700px;

//---------------------------------------------
// 5. Bootstrap Button
//---------------------------------------------

$btn-border-radius: 8px;
$btn-border-width: 2px;
$btn-padding-y: 16px;
$btn-padding-x: 48px;
$btn-font-weight: 500;
$btn-font-size: 20px;
$btn-line-height: 20px;

//---------------------------------------------
// 6. Alzheimer Colors
//---------------------------------------------

$white: white;
$black: black;
$dark-grey:  #102630;
$middle-grey:  #83858B;
$light-grey: #FAFAFA;
$ticket-bg: #0063a7;

//---------------------------------------------
// 7. Bootstrap Typography
//---------------------------------------------

$body-color: $dark-grey; // The default text color
$h1-font-size: 56px;
$h2-font-size: 32px;
$headings-line-height: 1;
$headings-font-weight: 400;
$headings-margin-bottom: 1rem;
$paragraph-margin-bottom: 1.5rem;
$font-weight-base: 300;
