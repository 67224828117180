//---------------------------------------------
//
//  Navigation Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

//---------------------------------------------
// 2. Component
//---------------------------------------------

.navigation {
  background-color: $white;
  padding: 0;

  .container {
    padding: 0 80px;
  }

  .offcanvas {
    transition: all 0.3s ease-in-out;
  }


  .offcanvas.show,.showing,.hiding {
    width: 60%;
    background: url('../images/menu-mobile-background.svg') no-repeat;
    background-size: cover;

    .btn-close {
      background: url('../images/close-icon.svg')  center/2em auto no-repeat;
      filter: brightness(0) invert(1);
      margin-right: 30px;
      width: 32px;
      height: 32px;
    }

    .offcanvas-body {
      margin-top: 4em;
      opacity: 1;
    }

    .nav-link {
      color: $white;
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 64px;
      margin-bottom: 25px;

      .lang-de & {
        font-size: 36px;
        line-height: 36px;
      }
    }

    .nav-language-de,.nav-language-en {
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.4px;
    }

    @media (max-width: $tablet-viewport) {
      width: 100%;
    }
  }

  .navbar-brand {
    padding: 0;
    img {
      width: 82px;
      height: 48px;
    }
  }

  .navbar-toggler {
    border: 0;

    .navbar-toggler-icon {
      background-image: url('../images/toggle-icon.svg')
    }
  }

  .navbar-nav {

    &__links {
      display: flex;
      padding-left: 16px;
      padding-right: 16px;
    }
    
    .nav-link {
      display: inline-block;
      font-size: 16px;
      font-style: normal;
      padding: 12px 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      border-bottom: none;

      &:not(.nav-link--lang) { 
        &:hover, &:active, &:visited {
          font-weight: 900;
        }
      }

      strong {
        font-weight: 900;
      }

      &--lang {
        padding-left: 0;
        padding-right: 0;
      }

      &--en {
        .lang-de & {
          display: none;
        }
      }

      &--de {
        .lang-en & {
          display: none;
        }
      }
    }
  }
}