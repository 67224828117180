.video-section {
    background-color: $light-grey;

    &__container {
        max-width: 1044px;
        padding-bottom: 0;
        isolation: isolate;

        @media (min-width: $desktop-viewport) {
            padding-top: 128px;
            padding-bottom: 64px;
        }
    }

    &__iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    &__thumbnail, iframe {
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
        opacity: 0;
        transition: all 0.3s;

        &.active {
            opacity: 1;
            z-index: 1;
        }
    }

    &__thumbnail {
        cursor: pointer;
        object-fit: cover;
    }
}