//---------------------------------------------
//
//  Hero Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../mixins/buttons";

//---------------------------------------------
// 2. Component
//---------------------------------------------

.hero-section {
    background-image: url('../images/hero-background.jpg');
    background-size: cover;
    background-position: center;

    &__container {
        padding-top: 16px;
        padding-bottom: 32px;
        
        @media (min-width: $desktop-viewport) {
            padding-top: 52px;
        }

        @media (min-width: $tablet-viewport) {
            padding-bottom: 94px;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media (min-width: $desktop-viewport) {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__content {

        @media (max-width: $desktop-viewport) {
            order: 2;
        }
    }

    &__logo-wrapper {
        @media (max-width: $desktop-viewport) {
            width: 100%;
        }
    }

    &__logo {
        display: block;
        width: 300px;

        @media (max-width: $desktop-viewport) {
            width: 120px;
            margin: 0 auto;
        }
    }


    &__title {
        margin-top: 32px;
        margin-bottom: 32px;
        
        @media (min-width: $tablet-viewport) {
            margin-bottom: 42px;
            margin-top: 22px;
        }
    }

    &__button-wrapper {
        text-align: left;

        @media (max-width: $tablet-viewport) {
            margin-bottom: 132px;
            margin-top: 32px;
        }
    }

    &__button {
        @include button(tertiary);
    }

    &__address {
        display: flex;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        
        @media (min-width: $tablet-viewport) {
            justify-content: flex-start;
            margin-top: 48px;
            margin-bottom: 40px;
        }

        strong {
            font-weight: 700;
        }

        > div:first-child {
            max-width: 100%;
            
            @media (min-width: $tablet-viewport) {
                max-width: 625px;
            }
        }
    }

    &__sticker {
        display: none;
        position: absolute;
        top: 0;
        right: 0;

        .lang-en &--en {
            @media (min-width: $desktop-viewport) {
                display: inline-block;
            } 
        }

        .lang-de &--de {
            @media (min-width: $desktop-viewport) {
                display: inline-block;
            } 
        }
    }
}