//---------------------------------------------
//
//  Footer Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../variables";

//---------------------------------------------
// 2. Component
//---------------------------------------------

.footer {
  background: $light-grey;

  .container {
    padding-top: 0;
  }

  &__description {
    p {
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__social-icons {
    text-align: right;

    a {
      padding-left: 24px;
      text-decoration: none;

      img {
        width: 32px;
        height: 32px;
      }

    }
  }
}
