.art-section {
    
    &__container {
        padding-bottom: 80px;
        padding-top: 80px;

        @media(min-width: $tablet-viewport) {
            padding-bottom: 104px;
            padding-top: 104px;
        }
    }

    &__grid {
        display: grid;
        gap: 63px;
        align-items: center;

        @media(min-width: $tablet-viewport) {
            gap: 48px 16px;
            grid-template-columns: 1fr 1fr;
        }

        @media(min-width: $desktop-viewport) {
            gap: 48px 20px;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        display: block;
        margin-top: 8px;
    }
}

.art-item {
    margin: 0;

    &__wrapper {
        position: relative;
        overflow: hidden;
    }

    &:hover .art-item__image--animated {
        opacity: 0;
        filter: brightness(1.4);
    }

    &__image {
        width: 100%;
        vertical-align: middle;

        &--animated {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 0.6s;
        }
    }
}