//---------------------------------------------
//
//  Learn more Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../mixins/buttons";

//---------------------------------------------
// 2. Component
//---------------------------------------------


.learn-more {
  background: $light-grey;

  &__image {
    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: $desktop-viewport) {
      width: 100%;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 40px;
    }

    &__buttons {
      .learn-more-button {
        @include button(primary);
      }
      .donate-button {
        @include button(secondary);
        margin-left: 16px;
      }
    }

    @media (max-width: $desktop-viewport) {
      margin-top: 64px;
      width: 100%;
    }
  }
}
