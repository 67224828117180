@import "range-slider-input/dist/style.css";

.exposition-section--visible + .art-section {
    @media(max-width: $tablet-viewport) {
        display: none;
    }
}

.exposition-section {
    background-color: $light-grey;
    display: none;

    &--visible {
        @media(max-width: $tablet-viewport) {
            display: block;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0;
    }
    
    &__container {
        padding-top: 80px;
        padding-bottom: 32px;
    }
}

.exposition-item {
    padding-top: 32px;
    padding-bottom: 92px;

    &__figure {
        position: relative;
        margin: 0 0 16px 0;
        isolation: isolate;
    }

    &__image {
        width: 100%;
        vertical-align: middle;
    }

    &__mask {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        background-size: cover;    
        background-position: center;
        transition: opacity 0.3s;

        &--1 {
            background-image: url("../images/exposition/mask1.png");
        }
        &--2 {
            background-image: url("../images/exposition/mask2.png");
        }
        &--3 {
            background-image: url("../images/exposition/mask3.png");
        }
        &--4 {
            background-image: url("../images/exposition/mask4.png");
        }
        &--5 {
            background-image: url("../images/exposition/mask5.png");
        }
    }

    &__slider-heading {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
    }

    &__title {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 16px 0;
    }

    &__description {
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
        margin-bottom: 0;
    }
}

.range-slider {
    border-radius: 5px;
    background-color: #9AACB1;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, .25);
    height: 5px;
    margin: 15px 0 28px 0;

    &__range {
        display: none;
    }

    &__thumb {

        &[data-upper] {
            width: 32px;
            height: 16px;
            border-radius: 8px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
            background-color: white;
        }

        &[data-lower] {
            width: 0;
        }        
    }
}