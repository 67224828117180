@mixin button($type) {
  color: $white;
  display: inline-flex;
  max-width: max-content;
  padding: 16px 40px;
  margin-bottom: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.6px;
  border: 2px solid $primary;
  border-radius: 8px;
  transition: none;
  text-decoration: none;

  @media (max-width: $tablet-viewport) {
    padding: 16px 32px;
  }

  &:hover {
    font-weight: 700;
  }

  @if $type == primary {
    color: $white;
    background: $primary;

    &:hover {
      box-shadow: 4px 4px 5px rgba(0,0,0,.25);
      color: $white;
    }
  } @else if $type == secondary {
    background: transparent;
    color: $primary;

    &:hover {
      box-shadow: 1px 2px 4px rgba(0,0,0,.25);
      text-shadow: 1px 2px 4px rgba(0,0,0,.25);
    }
  } @else if $type == tertiary {
    background: transparent;
    color: $white;
    border-color: $white;

    &:hover {
      background: $white;
      color: $primary;
    }
  }
}
