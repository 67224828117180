//---------------------------------------------
//
//  Ticket Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

@import "../variables";
@import "../mixins/buttons";

//---------------------------------------------
// 2. Component
//---------------------------------------------

.ticket-section {
  background: $light-grey;

  &__title {
    margin-bottom: 56px;

    h2 {
      font-family: $font-family-base;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
    }
  }

  &__content {
    margin: 0 auto;
    border-radius: 8px;

    &__left {
      padding: 0;

      @media (max-width: $desktop-viewport) {
        width: 100%;
      }

      @media (min-width: $tablet-viewport) and (max-width: $desktop-viewport) {
        border-radius: 8px 8px 0 0;
        overflow: hidden;
      }

      .mobile-image{
        display: none;
        width: 100%;
        height: auto;

        @media (max-width: $tablet-viewport) {
          display: block;
        }
      }

      .desktop-image{
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: $tablet-viewport) {
          display: none;
        }
      }

      @media (max-width: $tablet-viewport) {
        width: 100%;
        padding: 0;
      }
    }

    &__right {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $ticket-bg;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;

      &__logo {
        img {
          width: 100%;
          height: auto;
          max-width: 280px;
          margin-bottom: 40px;

          @media (max-width: $tablet-viewport) {
            max-width: 200px;
            margin-bottom: 42px;
          }

          @media (max-width: 1200px) {
            max-width: 180px;
            margin-bottom: 25px;
          }

          @media (max-width: 955px) {
            max-width: 120px;
            margin-bottom: 10px;
          }

          @media (max-width: 910px) {
            display: none;
          }

          @media (max-width: $tablet-viewport) {
            display: block;
            max-width: 200px;
            margin-bottom: 25px;
          }
        }

        @media (max-width: $tablet-viewport) {
          width: 100%;
          display: block;
          background-size: 100% 100%;
        }
      }

      &__text {
        margin-bottom: 32px;
        p {
          color: $white;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 32px;
          margin: 0;

          @media (max-width: $desktop-viewport) {
            font-size: 16px;
          }

          //custom size to make it looks less crumble
          @media (max-width: 1200px) {
            font-size: 18px;
          }

          @media (max-width: 955px) {
            font-size: 14px;
          }
        }

        .font-bold {
          font-weight: 900;
        }

        @media (max-width: $tablet-viewport) {
          width: 49%;
          display: inline-block;
        }

        //custom size to make it looks less crumble
        @media (max-width: 1200px) {
          margin-bottom: 16px;
        }

        @media (max-width: 440px) {
          width: 100%;
        }
      }

      &__button {
        @include button(primary);

        @media (max-width: $desktop-viewport) {
          font-size: 18px;
          padding: 16px 32px;
        }

        //custom size to make it looks less crumble
        @media (max-width: 1200px) {
          font-size: 16px;
          padding: 10px 10px;
        }

        @media (max-width: 850px) {
          font-size: 14px;
        }
      }

      @media (max-width: 955px) {
        padding: 0 15px;
      }

      @media (max-width: $desktop-viewport) {
        width: 100%;
        display: block;
        padding: 40px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
      }
    }

    @media (max-width: $tablet-viewport) {
      background: none;
    }
  }

  &__text {
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    color: $white;
    max-width: 282px;

    @media (min-width: $desktop-viewport) and (max-width: 1300px) {
      font-size: 20px;
      line-height: 24px;
    }

    .lang-de & {
      max-width: 315px;
    }
    
    p {
      margin-bottom: 36px;
    }
  }

  &__row {
    display: flex;
    gap: 24px;

    @media (min-width: $desktop-viewport) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  &__figure {
    margin: 0;
    padding: 0;

    @media (min-width: $desktop-viewport) {
      display: flex;
      align-items: flex-end;
      gap: 9px;
    }
  }

  &__qr {
    max-width: 100%;

    @media (min-width: $desktop-viewport) {
      width: 16vw;
    }

    @media (min-width: $desktop-viewport) and (max-width: 1300px) {
      width: 14vw;
    }
  }

  &__caption {
    color: $white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;

    @media (min-width: $desktop-viewport) {
      order: -1;
      width: 32px;
      white-space: nowrap;
      transform: rotate(-90deg) translateY(30px);
      transform-origin: left bottom;
      margin-top: 0;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

