//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  font-family: $font-family-base;
  overflow: auto !important;
}

//---------------------------------------------
// 3. Components
//---------------------------------------------

@import "components/base";
@import "components/navigation";
@import "components/slider";
@import "components/hero";
@import "components/about";
@import "components/learn-more";
@import "components/ticket-section";
@import "components/footer";
@import "components/collaborating";
@import "components/art";
@import "components/exposition";
@import "components/video";
