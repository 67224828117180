@import "slick-carousel/slick/slick.scss";

.artist {

    &__image-wrapper {
        position: relative;
        padding-top: 70%;

        &:hover .artist__overlay {
            opacity: 1;
        }
    }

    &__overlay {
        background-color: rgba(0, 21, 35, .5);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.6s;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        backdrop-filter: blur(20px);
    }

    &__title {
        color: $dark-grey;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;

        @media (min-width: $desktop-viewport) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__image {
        object-fit: cover;    
    }
}

.full-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slick-slide {
    padding: 0 10px;
    
    & > div {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 40px;
        
            .artist__description {

                @media (min-width: $tablet-viewport) {
                    height: 44px;
                }
        
                @media (min-width: $desktop-viewport) {
                    height: 48px;
                }
            }
        }
    }
}

.slick-list {
    margin: 0 -10px;
    
    @media (max-width: $desktop-viewport) {
        overflow: visible;
    }
}

.collaboration-section {
    width:100%;
    background-color: #FAFAFA;
    overflow: hidden;

    &__title {
        margin-right: auto;
        font-size: 56px;
        line-height: 56px;
        
        @media (max-width: $tablet-viewport) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        margin-bottom: 40px;
    }

    &__arrows {
        @media (max-width: $desktop-viewport) {
            display: none;
        }
    }
}

.arrow {
    width: 48px;
    height: 48px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0.8;

    svg {
        fill: $dark-grey;
        transition: fill 0.6s;
    }

    &.slick-disabled svg {
        fill: $middle-grey;
    }

    &--next {
        transform: scaleX(-1);
    }
}